import { observer } from 'mobx-react'
import { FC } from 'react'
import { LinkList, LinkRow, LinkTitle, Icon, SectionTitle, LinkListWrap } from './Links.styles'
import {
  ShortcutLogo,
  OnboardingDashboardLogo,
  OpsGenieLogo,
  ZendeskLogo,
  ConfluenceLogo,
  SalesforceLogo,
  AzureLogo,
  GitlabLogo,
  SantanderDashboardLogo,
  StatusPageLogo,
} from './Icons'
import AWSLogo from './Logos/aws.png'
import DatadogLogo from './Logos/datadog.png'
import SignicatITLogo from './Logos/signicatIT.png'
import SpaceliftLogo from './Logos/spacelift.png'
import EidvLogo from './Logos/eidv.png'
import { SignicatLogo } from '@/components/Brandings/SignicatLogo'
import { ToolTitle } from '../../Tools.styles'

export const Links: FC = observer(() => {
  return (
    <>
      <ToolTitle>Important links list</ToolTitle>
      <LinkListWrap>
        <LinkList>
          <SectionTitle>Pink</SectionTitle>

          <LinkRow href="https://signicat.center/">
            <Icon>
              <OnboardingDashboardLogo />
            </Icon>
            <LinkTitle>Onboarding Dashboard</LinkTitle>
          </LinkRow>

          <LinkRow href="https://santander.signicat.center/">
            <Icon>
              <SantanderDashboardLogo />
            </Icon>
            <LinkTitle>Santander Dashboard</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicatinternal:Z10broChu7@documentation.prod.identity.signicat.global/onboarding">
            <Icon>
              <SignicatLogo />
            </Icon>
            <LinkTitle>Onboarding documentation</LinkTitle>
          </LinkRow>
        </LinkList>

        <LinkList>
          <SectionTitle>Pink Saas</SectionTitle>

          <LinkRow href="https://app.datadoghq.eu/dashboard/dby-p3c-sf3/onboarding-dashboard">
            <Icon>
              <img src={DatadogLogo} />
            </Icon>
            <LinkTitle>Datadog</LinkTitle>
          </LinkRow>

          <LinkRow href="https://attempt-identity.signin.aws.amazon.com/console">
            <Icon>
              <img src={AWSLogo} />
            </Icon>
            <LinkTitle>AWS</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat.app.opsgenie.com/teams/dashboard/34617119-b20e-498e-b7b6-b672aa037e98/main">
            <Icon>
              <OpsGenieLogo />
            </Icon>
            <LinkTitle>OpsGenie</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat-global-solutions.app.spacelift.io">
            <Icon>
              <img src={SpaceliftLogo} />
            </Icon>
            <LinkTitle>Spacelift</LinkTitle>
          </LinkRow>

          <LinkRow href="https://portal.azure.com/?quickstart=True#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/Overview">
            <Icon>
              <AzureLogo />
            </Icon>
            <LinkTitle>Entra ID</LinkTitle>
          </LinkRow>

          <LinkRow href="https://app.shortcut.com/signicat">
            <Icon>
              <ShortcutLogo />
            </Icon>
            <LinkTitle>Shortcut</LinkTitle>
          </LinkRow>
        </LinkList>

        <LinkList>
          <SectionTitle>Signicat</SectionTitle>

          <LinkRow href="https://signicat.zendesk.com/agent">
            <Icon>
              <ZendeskLogo />
            </Icon>
            <LinkTitle>Zendesk</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat.atlassian.net/wiki/spaces/PINK/overview?homepageId=194084176082">
            <Icon>
              <ConfluenceLogo />
            </Icon>
            <LinkTitle>Confluence</LinkTitle>
          </LinkRow>

          <LinkRow href="https://dashboard.signicat.com/">
            <Icon>
              <SignicatLogo />
            </Icon>
            <LinkTitle>Signicat dashboard</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat.atlassian.net/servicedesk/customer/portals">
            <Icon>
              <img src={SignicatITLogo} />
            </Icon>
            <LinkTitle>Signicat internal-it SD</LinkTitle>
          </LinkRow>

          <LinkRow href="https://manage.statuspage.io/">
            <Icon>
              <StatusPageLogo />
            </Icon>
            <LinkTitle>Statuspage</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat.my.salesforce.com/">
            <Icon>
              <SalesforceLogo />
            </Icon>
            <LinkTitle>Salesforce</LinkTitle>
          </LinkRow>

          <LinkRow href="https://gitlab.com/signicat">
            <Icon>
              <GitlabLogo />
            </Icon>
            <LinkTitle>Gitlab</LinkTitle>
          </LinkRow>
        </LinkList>

        <LinkList>
          <SectionTitle>ElectronicId</SectionTitle>

          <LinkRow href="https://etrust-sandbox.electronicid.eu/registry2/login">
            <Icon>
              <img src={EidvLogo} />
            </Icon>
            <LinkTitle>ElectronicID RA sandbox</LinkTitle>
          </LinkRow>

          <LinkRow href="https://etrust-live.electronicid.eu/registry2/login">
            <Icon>
              <img src={EidvLogo} />
            </Icon>
            <LinkTitle>ElectronicID RA live</LinkTitle>
          </LinkRow>
        </LinkList>
      </LinkListWrap>
    </>
  )
})
